import logoLink from '../resources/boschhomecomfort.png';
import companyLogoLink from '../resources/boschhomecomfort.png';
import footerLogoLink from '../resources/boschhomecomfort_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#005691',
    panel_highlight: '#005691',
    chat_background_right: '#CCE8F5',
    chat_background_left: '#DFE0E1',

    active: '#008ECF',
    inactive: '#005691',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#CCE8F5',

    phonebook_dropdown: '#CCE8F5',
    phonebook_dropdown_svg: '#000000',

    primary_light: '#CCE8F5',
    primary: '#008ECF',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#005691',
    text_on_secondary: 'white',
    focus_active: '#008ECF',

    red: '#db1c1c',
    green: '#3ac13a',

    connecting: '#868889',
    connected: '#008ECF',
    aborted: '#DC2D4B',

    font_color: '#000000',
    link_color: '#005691',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#DFE0E1',

    gridbox_header_bg: '#525F6B',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#008ECF',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Bosch Home Comfort';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
